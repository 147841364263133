<template>
  <div>
    <!-- Header Section Starts Here -->
    <header>
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <nav class="menu">
              <div class="menu__logo">
                <a href="index.html">Portfolio By Malcom</a>
              </div>
              <div class="menu__bar">
                <span></span>
                <span></span>
                <span></span>
              </div>
              <ul class="menu-data">
                <li><a href="index.html" class="active-menu">Accueil</a></li>
                <li><a href="#about">Qui suis-je ?</a></li>
                <li><a href="#services">Compétences</a></li>
                <li><a href="#portfolio">Mes Realisations </a></li>
              </ul>
              <div class="menu-button">
                <a href="#contact" class="btn btn_yellow">Contactez moi</a>
              </div>
            </nav>
            <div class="mobile-menu">
              <ul class="mobile-menu__main">
                <li><a href="index.html" class="active-menu">Home</a></li>
                <li><a href="#about">About</a></li>
                <li><a href="#services">Services</a></li>
                <li><a href="#portfolio">Work</a></li>
                <li><a href="#blog">Blog</a></li>
              </ul>
              <div class="mobile-menu__button">
                <a href="#contact" class="btn">Hire Me</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- Header Section Ends Here-->

    <!-- Banner Section Starts Here-->
    <section class="banner-area">
      <div class="container mt-5">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="banner-area__heading">
              <h1 class="text-white">
                Webdesigner <span>integrateur</span>
              </h1>
            </div>
            <div class="banner-area__text">
              <p class="text-white">
                Le designer préférer du de que vous préférez.
              </p>
            </div>
            <a class="btn btn_yellow" href="#portfolio">View Project</a>
          </div>
          <div class="col-lg-6 text-md-center mt-5 mt-md-0">
            <img
              src="./assets/images/Banner/Photo.svg"
              alt="Person"
              class="img-fluid overflow-hidden img_sm"
            />
          </div>
        </div>
      </div>
    </section>
    <!-- Banner Section Ends Here-->

    <!-- About Section Starts Here-->
    <section class="section_padding about-me" id="about">
      <div class="container">
        <div class="row align-items-center">
          <div
            class="col-lg-6 order-2 order-md-2 order-lg-1 text-center text-lg-left mb-0"
          >
            <div class="about-me__image mb-0">
              <img
                src="./assets/images/About/fab665c7-ad4c-446a-a08a-4fc40d06d759.png"
                alt="Kevin"
                class="img-fluid"
              />
            </div>
          </div>
          <div
            class="col-lg-6 order-1 order-md-1 order-lg-2 mb-5 mb-md-5 mb-lg-0"
          >
            <div class="about-me__info">
              <h3>
                Bonjour,
                Je suis Malcom Merkiled.
              </h3>
              <p>
                Je suis un apprenti Webdesigner-Intégrateur de bientôt 25 ans, passionné par la création numérique et le développement web. Grâce à l’alternance et à une formation combinant apprentissage structuré et autodidacte, j’ai développé des compétences solides et polyvalentes. Mon objectif est de faire de ce métier ma carrière et de contribuer à des projets innovants.
              </p>
              <p>
                Découvrez mon parcours et mes réalisations en téléchargeant mon
                CV et en explorant mon portfolio, l'essence de mon expertise et
                de ma créativité!
              </p>
              <div class="d-flex">
                <a
                  href="./assets/CV Malcom Merkiled (OFFICIEL).pdf"
                  download
                  class="btn btn_white ml-1"
                  >Download CV</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="about-me__triangle">
        <img
          src="./assets/images/About/Triangle.png"
          alt="Triangle"
          class="img-fluid"
        />
      </div>
    </section>
    <!-- About Section Ends Here-->

    <!-- Services Section Starts Here-->
    <section class="section_padding services" id="services">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2 class="section_heading text-center">Mes Compétences</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <!-- Services Item Starts Here -->
            <div class="services-item text-center">
              <div class="services-item__icon">
                <img
                  src="./assets/images/Icon/02.png"
                  alt="icon"
                  class="img-fluid"
                />
              </div>
              <div class="services-item__heading">
                <h4 class="mb-0">Integration html</h4>
              </div>
              <div class="services-item__text">
                <p class="mb-0">
                  Wordpress, Integration à partir d'un PSD, HTML5, CSS3, SCSS
                </p>
              </div>
            </div>
            <!-- Services Item Ends Here -->
          </div>
          <div class="col-lg-4">
            <!-- Services Item Starts Here -->
            <div class="services-item text-center">
              <div class="services-item__icon">
                <img
                  src="./assets/images/Icon/01.png"
                  alt="icon"
                  class="img-fluid"
                />
              </div>
              <div class="services-item__heading">
                <h4 class="mb-0">Developpement Web</h4>
              </div>
              <div class="services-item__text">
                <p class="mb-0">
                  Technologie utiliser : Synfony (TWIG), Bootstrap, Vue.js,
                  React.js, Wordpress
                </p>
              </div>
            </div>
            <!-- Services Item Ends Here -->
          </div>
          <div class="col-lg-4">
            <!-- Services Item Starts Here -->
            <div class="services-item text-center">
              <div class="services-item__icon">
                <img
                  src="./assets/images/Icon/03.png"
                  alt="icon"
                  class="img-fluid"
                />
              </div>
              <div class="services-item__heading">
                <h4 class="mb-0">Creation de contenue</h4>
              </div>
              <div class="services-item__text">
                <p class="mb-0">
                  Création de logo, Maquettage, Montage photos, à l'aide de la
                  suite adobe (PhotoShop, Indisign, illustrator.)
                </p>
              </div>
            </div>
            <!-- Services Item Ends Here -->
          </div>
        </div>
      </div>
    </section>
    <!-- Services Section Ends Here-->

    <!-- Portfolio Section Starts Here-->
    <section
      class="my-portfolio"
      id="portfolio"
      style="background-image: url(./assets/images/portfolio//bg.png)"
    >
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2 id="portfolio-menu" class="section_heading text-center m-5">
              Mon Portfolio
            </h2>
          </div>
        </div>

        <!-- Menu des Création -->
        <div id="fixed-menu" class="portfolio-menu menu1" style="display: none">
          <a href="#portfolio-menu">Flyer SAPAAD</a>
          <a href="#project2">Maquetteage SAPAAD</a>
          <a href="#project3">En formation</a>
          <a href="#project4">Stage Tacoloco</a>
          <a href="#project5">Conception de logo pour SAPAAD</a>
        </div>

        <!-- Menu des Création fin -->

        <!-- Image des Créations -->
        <div class="gallery">
          <div id="project1" class="image-container">
            <h4 class="m-5">
              Conception de A a Z des flyers de association SAPAAD Martinique
            </h4>
            <img
              src="@/assets/images/Portfolio/Flyer-SAPAAD.png"
              alt="Image 1"
              class="space"
            />
            <img
              src="@/assets/images/Portfolio/Flyer-SAPAAD-verso.png"
              alt="Image 2"
              class="space"
            />
          </div>
          <div id="project2" class="gallery">
            <h4 class="m-5">
              Conception des Maquettes du site de association SAPAAD Martinique
              (en cours)
            </h4>
            <div class="image-container">
              <img
                src="@/assets/images/Portfolio/Desktop-1.png"
                alt="Image 1"
                class="space"
              />
            </div>
            <div class="image-container">
              <img
                src="@/assets/images/Portfolio/Desktop-2.png"
                alt="Image 1"
                class="space"
              />
            </div>
            <div class="image-container">
              <img
                src="@/assets/images/Portfolio/Desktop-3.png"
                alt="Image 1"
                class="space"
              />
            </div>
            <div class="image-container">
              <img
                src="@/assets/images/Portfolio/Desktop-4.png"
                alt="Image 1"
                class="space"
              />
            </div>
            <div class="image-container">
              <img
                src="@/assets/images/Portfolio/Maquette-Sapaad_Page_1.jpg"
                alt="Image 1"
                class="space"
              />
            </div>

            <div class="image-container">
              <img
                src="@/assets/images/Portfolio/Maquette-Sapaad_Page_3.jpg"
                alt="Image 1"
                class="space"
              />
            </div>

            <div class="image-container">
              <img
                src="@/assets/images/Portfolio/Maquette-Sapaad_Page_4.jpg"
                alt="Image 1"
                class="space small-image"
              />
            </div>

            <div class="image-container">
              <img
                src="@/assets/images/Portfolio/Maquette-Sapaad_Page_5.jpg"
                alt="Image 1"
                class="space small-image"
              />
            </div>
          </div>
          <div id="project3" class="image-container">
            <h4 class="m-5">Réalisation faitent en Formation en alternance</h4>
            <img
              src="@/assets/images/Portfolio/creation.jpg"
              alt="Image 1"
              class="space"
            />
            <img
              src="@/assets/images/Portfolio/Flyer-Helena-fleurs.jpg"
              alt="Image 1"
              class="space"
            />
            <img
              src="@/assets/images/Portfolio/Logo-test.png"
              alt="Image 1"
              class="space"
            />
          </div>

          <div id="project4" class="image-container image-container1">
            <h4 class="m-5">Projet fait en stage</h4>
            <img src="@/assets/images/Portfolio/Projet2.jpeg" alt="Image 1" />
            <a href="https://demo.tacoloco.marengoweb.fr/"
              >Cliquez ici pour voir le Site</a
            >
          </div>

          <div id="project5" class="image-container">
            <h4 class="m-5">Refonte du Logo de SAPAAD</h4>
            <img
              src="@/assets/images/Portfolio/Logo-Sapaad.png"
              alt="Image3"
              class="space"
            />
            <img
              src="@/assets/images/Portfolio/Logo-Officiel-Sapaad.png"
              alt="Image3"
              class="space"
            />
          </div>
        </div>
        <!-- Image des Créations fin -->


        <div class="row">
          <div class="col-12 mt-lg-5 mt-md-0 mt-2 text-center">
            <a href="#" class="btn">View All Project</a>
          </div>
        </div>
      </div>
    </section>
    <!-- Portfolio Section Ends Here-->

    <!-- Testimonials Section Starts Here-->
    <!-- <section class="section_padding testimonials pb-0">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2 class="section_heading text-center">Testimonials</h2>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="swiper-container">
                    <div class="swiper-wrapper">
                       <div class="swiper-slide">
                            <div class="testimonial-left">
                                <div class="testimonial-left__heading">
                                    <h5>Patrick Hill1</h5>
                                    <span>-CEO of webflow university</span>
                                </div>
                                <div class="testimonial-left__text">
                                    <p>
                                        In consequat, quam id sodales hendrerit, eros mi molestie leo, nec lacinia risus neque
                                        tristique augue. Proin tempus urna vel congue elementum. Vestibulum euismod accumsan
                                        dui, ac iaculis sem viverra eu. Donec convallis, elit vitae ornare cursus, libero purus
                                        facilisis felis.
                                    </p>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    
                   
                </div>
            </div>
            <div class="col-lg-6 offset-lg-1 d-none d-md-none d-lg-block">
                <img src="./assets/images/Testimonial/Photo5.svg" alt="clients" class="img-fluid">
            </div>
        </div>
    </div>
</section> -->
    <!-- Testimonials Section Ends Here-->

    <!-- Contact Section Starts Here-->
    <section class="section_padding contact" id="contact">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2 class="section_heading text-center">Contact</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-5 offset-lg-1 col-md-12 order-2">
            <div class="contact-info">
              <div class="contact-info__text">
                <h6>Par Mail</h6>
                <p>malcommerkiled@gmail.com</p>
              </div>
              <div class="contact-info__text">
                <h6>Par Téléphone</h6>
                <p>0760 23 60 49</p>
              </div>
              <div class="contact-info__links">
                <h6>Mes réseaux</h6>
                <a
                  href="https://www.linkedin.com/in/malcom-merkiled-81a24b215/"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="Icon_awesome-linkedin-in"
                      data-name="Icon awesome-linkedin-in"
                      d="M3.134,14H.231V4.653h2.9ZM1.681,3.378a1.689,1.689,0,1,1,1.681-1.7A1.7,1.7,0,0,1,1.681,3.378ZM14,14H11.1V9.45c0-1.084-.022-2.475-1.509-2.475-1.509,0-1.74,1.178-1.74,2.4V14h-2.9V4.653H7.736V5.928h.041a3.05,3.05,0,0,1,2.746-1.509C13.46,4.419,14,6.353,14,8.866V14Z"
                      transform="translate(0 0)"
                      fill="#404040"
                    />
                  </svg>
                </a>
                <a href="https://www.instagram.com/malcom.mk/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14.003"
                    height="14"
                    viewBox="0 0 14.003 14"
                  >
                    <path
                      id="Icon_awesome-instagram"
                      data-name="Icon awesome-instagram"
                      d="M7,4.405A3.589,3.589,0,1,0,10.59,7.995,3.584,3.584,0,0,0,7,4.405Zm0,5.923A2.334,2.334,0,1,1,9.334,7.995,2.338,2.338,0,0,1,7,10.328Zm4.573-6.07a.837.837,0,1,1-.837-.837A.835.835,0,0,1,11.574,4.258Zm2.377.85a4.143,4.143,0,0,0-1.131-2.933A4.17,4.17,0,0,0,9.887,1.044c-1.156-.066-4.62-.066-5.776,0A4.164,4.164,0,0,0,1.178,2.171,4.157,4.157,0,0,0,.047,5.1c-.066,1.156-.066,4.62,0,5.776a4.143,4.143,0,0,0,1.131,2.933,4.176,4.176,0,0,0,2.933,1.131c1.156.066,4.62.066,5.776,0a4.143,4.143,0,0,0,2.933-1.131,4.17,4.17,0,0,0,1.131-2.933c.066-1.156.066-4.617,0-5.773Zm-1.493,7.013a2.363,2.363,0,0,1-1.331,1.331A15.429,15.429,0,0,1,7,13.733a15.549,15.549,0,0,1-4.127-.281,2.363,2.363,0,0,1-1.331-1.331,15.429,15.429,0,0,1-.281-4.127,15.549,15.549,0,0,1,.281-4.127A2.363,2.363,0,0,1,2.874,2.537,15.429,15.429,0,0,1,7,2.256a15.549,15.549,0,0,1,4.127.281,2.363,2.363,0,0,1,1.331,1.331,15.429,15.429,0,0,1,.281,4.127A15.42,15.42,0,0,1,12.458,12.121Z"
                      transform="translate(0.002 -0.995)"
                      fill="#404040"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-md-12 order-1 mb-5 mb-md-5 mb-lg-0">
            <div class="contact-form">
              <form ref="form" @submit.prevent="sendEmail">
                <input
                  v-model="form.name"
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Nom"
                  class="form-control"
                />
                <input
                  v-model="form.email"
                  type="email"
                  placeholder="Email"
                  id="email"
                  name="email"
                  class="form-control"
                  required
                />
                <input
                  v-model="form.subject"
                  type="text"
                  placeholder="Objet"
                  id="subject"
                  name="subject"
                  class="form-control"
                />
                <textarea
                  v-model="form.textarea"
                  id="textarea"
                  name="textarea"
                  cols=""
                  rows=""
                  placeholder="Votre message"
                  class="form-control"
                ></textarea>
                <div class="row ml-0">
                  <button type="submit" class="btn my-5 mr-5" value="Send">
                    Envoyer
                  </button>
                  <div
                    class="alert alert-success my-5"
                    role="alert"
                    v-show="isMailSend"
                  >
                    Message envoyé
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Contact Section Ends Here-->

    <!--Copyright Area Starts Here-->
    <div class="copyright">
      <p class="text-center"><span>&copy;</span> 2023-Malcom Merkiled</p>
    </div>
    <!--Copyright Area Ends Here-->

    <!-- All js Goes here -->
    <!-- <script src="assets/js/jquery-3.5.1.min.js"></script>
<script src="assets/js/jquery-3.5.1.slim.min.js"></script>
<script src="assets/js/popper.min.js"></script>
<script src="assets/js/bootstrap.min.js"></script>
<script src="assets/js/swiper-bundle.min.js"></script>
<script src="assets/js/app.js"></script> -->
  </div>
</template>

<script>
import emailjs from "@emailjs/browser";

export default {
  name: "App",
  components: {},
  data() {
    return {
      form: {
        name: null,
        subject: null,
        email: null,
        textarea: null,
      },
      isMailSend: false,
    };
  },
  methods: {
    sendEmail() {
      emailjs
        .send(
          "service_bfcyj09",
          "template_zyl5i19",
          this.form,
          "BYwZsxqJ40LvzGipQ"
        )
        .then(
          (result) => {
            console.log("SUCCESS!", result.text);
            this.form.name = null;
            this.form.subject = null;
            this.form.email = null;
            this.form.textarea = null;
            this.isMailSend = true;
          },
          (error) => {
            console.log("FAILED...", error);
            this.isMailSend = false;
          }
        );
    },
  },
  mounted() {
    // Script pour afficher/masquer le menu en fonction du défilement
    document.addEventListener("scroll", function () {
      const portfolioSection = document.querySelector("#portfolio");
      const menu = document.querySelector("#fixed-menu");

      // Vérifie si la section "Mon Portfolio" est visible
      const sectionTop = portfolioSection.getBoundingClientRect().top;
      const sectionBottom = portfolioSection.getBoundingClientRect().bottom;

      if (sectionTop <= window.innerHeight / 5 && sectionBottom >= 0) {
        menu.style.display = "block"; // Affiche le menu
      } else {
        menu.style.display = "none"; // Masque le menu
      }
    });
  },
};
</script>

<style></style>
